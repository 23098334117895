import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTruck, faTrash, faHamburger,faStickyNote, faQrcode, faBicycle} from '@fortawesome/free-solid-svg-icons';
import {
    Link
  } from "react-router-dom"
import Utilities from '../../../js/Utilities';
import './ReportsHeader.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';




function HeaderButton(props){
    return <Link className={`headerBtn${props.active ? ' btnActive' : ''}`} to={props.to}> 
    <FontAwesomeIcon className='btnIcon' icon={props.icon} size='2x'/>
    <Typography>{props.title}</Typography>
</Link>
  }

function ReportsHeader(props){
    const location = useLocation();

    if(firebase.auth().currentUser && !firebase.auth().currentUser.email.endsWith("@gottea.in")){
        firebase.auth().signOut();
    }
    return <React.Fragment><div className="headerContainer">
    <HeaderButton to={'/'} active={location.pathname === '/'} icon={faHamburger} title={'Food Tracker'}/>
    <HeaderButton to={'/inventoryOrder'} active={location.pathname === '/inventoryOrder'} icon={faTruck} title={'Inventory'}/>
    <HeaderButton to={'/qrpayment'} active={location.pathname === '/qrpayment'} icon={faQrcode} title={'QR Payment'}/>
    <HeaderButton to={'/packaging'} active={location.pathname === '/packaging'} icon={faBicycle} title={'Delivery'}/>
    <HeaderButton to={'/sticker'} active={location.pathname === '/sticker'} icon={faStickyNote} title={'Stickers'}/>
    <div className='headerBtn logOutBtn' onClick={Utilities.logout}> 
        <FontAwesomeIcon className='btnIcon' icon={faSignOutAlt} size='2x'/>
        Log Out
    </div>
</div>
<p className="welcomeMsg">{firebase.auth().currentUser  ? firebase.auth().currentUser.displayName : 'Welcome'}</p>
</React.Fragment>

}

export default ReportsHeader;